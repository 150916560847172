import { MouseEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Typography, Link } from '@mui/material'
import { normalizePrice } from '@obeta/utils/lib/data-formatter'
import { useMetalQuotations } from '@obeta/data/lib/hooks/useMetalQuotations'
import { ShopRoutes } from '@obeta/utils/lib/variables'
import { useHistory } from '@obeta/data/lib/hooks/useHistoryApi'
import styles from './MobileStartPageFooter.module.scss'

export const MobileStartPageFooter = () => {
  const { t } = useTranslation()
  const { aluminium, copper } = useMetalQuotations()
  const history = useHistory()

  return (
    <Box className={styles.wrapper}>
      <div className={styles.metalQuotationSection}>
        <div className={styles.metalQuotationTitle}>
          <Typography variant="headline4Bold"> {t('FOOTER.CURRENT_METALQUOTATION')} </Typography>
        </div>
        <div className={styles.quotationItems}>
          <div className={styles.quotationItem}>
            <Typography variant="body">
              {t('FOOTER.ALUMINIUM_QUOTATION_TEXT', {
                aluminiumQuotation: normalizePrice(aluminium, false),
              })}
            </Typography>
          </div>
          <div className={styles.quotationItem}>
            <Typography variant="body">
              {t('FOOTER.COPPER_QUOTATION_TEXT', {
                copperQuotation: normalizePrice(copper, false),
              })}
            </Typography>
          </div>
        </div>
      </div>

      <div className={styles.legalNote}>
        <Link
          href={ShopRoutes.Terms}
          onClick={(e: MouseEvent<HTMLAnchorElement>) => {
            e.preventDefault()
            history.push(ShopRoutes.Terms)
          }}
        >
          <Typography variant="bodyBold">{t('CUSTOMER_PROFILE.TERMS_AND_CONDITIONS')}</Typography>
        </Link>
        <Link
          href={ShopRoutes.AboutUs}
          onClick={(e: MouseEvent<HTMLAnchorElement>) => {
            e.preventDefault()
            history.push(ShopRoutes.AboutUs)
          }}
        >
          <Typography variant="bodyBold">{t('CUSTOMER_PROFILE.IMPRINT')}</Typography>
        </Link>
        <Link
          href={ShopRoutes.PrivacyProtection}
          onClick={(e: MouseEvent<HTMLAnchorElement>) => {
            e.preventDefault()
            history.push(ShopRoutes.PrivacyProtection)
          }}
        >
          <Typography variant="bodyBold">{t('CUSTOMER_PROFILE.DATA_PROTECTION')}</Typography>
        </Link>
      </div>
    </Box>
  )
}
