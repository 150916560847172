import React from 'react'
import { StartPage } from './StartPage'
import { obetaCategory } from './storiesMocks'
import { useUserDataV2 } from '@obeta/data/lib/hooks/useUserDataV2'
import { strengths } from './constants/strengths'

export const StartPageContainer: React.FC = () => {
  const { isLoggedIn, permissions } = useUserDataV2()

  return (
    <StartPage
      obetaCategory={obetaCategory}
      strengths={strengths}
      isLoggedIn={isLoggedIn}
      permissions={permissions}
    />
  )
}
