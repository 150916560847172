import React, { useEffect, useRef, useState } from 'react'
import { ProductsSwiper } from '../articleDetailsPage/ProductsSwiper'
import styles from './OrdersSection.module.scss'
import { ApolloProvider, useApolloClient } from '@apollo/client'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { OrderListItemV2 } from '@obeta/components/lib/orders/OrderListItemV2'
import clsx from 'clsx'
import { OrderForListPage } from '@obeta/models/lib/schema-models/order-list'

interface Props {
  orders: OrderForListPage[]
}

export const OrdersSection: React.FC<Props> = (props) => {
  const apolloClient = useApolloClient()
  const { orders } = props

  return (
    <ApolloProvider client={apolloClient}>
      <OrdersSectionView orders={orders} />
    </ApolloProvider>
  )
}

export const OrdersSectionView: React.FC<Props> = (props) => {
  const { mobile, tablet, tabletWide, desktop } = useBreakpoints()
  const { orders } = props
  /**
   * Layout needs to be calculated with a JavaScript solution due to two different dynamic item heights.
   * The total height of the item cards needs to be the sum of the tallest items, even if these are not part of the same item card.
   */
  const [tallestItemHeight, setTallestItemHeight] = useState<number>(0)
  const cardRefs = useRef<Array<HTMLDivElement | null>>([])

  const updateItemHeights = () => {
    const heights = cardRefs.current
      .map((ref) => {
        if (ref !== null) {
          return (ref as HTMLDivElement).clientHeight
        }
        return 0
      })
      .filter(Boolean)
    const max = Math.max(...heights)
    setTallestItemHeight(max)
  }
  useEffect(() => {
    cardRefs.current = cardRefs.current.slice(0, orders.length)
  }, [orders])
  useEffect(() => {
    updateItemHeights()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orders, cardRefs.current])

  useEffect(() => {
    window.addEventListener('resize', updateItemHeights)
    return () => {
      window.removeEventListener('resize', updateItemHeights)
    }
  }, [])

  return (
    <div
      className={clsx(
        styles['order-list-item-container'],
        (orders.length === 4 && desktop) || (orders.length === 3 && tabletWide && styles.centered)
      )}
    >
      {!tablet && !mobile && (
        <div className={styles.itemWrapper}>
          {/* Only show 3 orders for tabletWide even if 4 have been fetched*/}
          {orders.map((order, idx) =>
            tabletWide && idx === 3 ? null : (
              <OrderListItemV2
                key={order.id}
                index={idx}
                tallestItemHeight={tallestItemHeight}
                ref={(element) => {
                  cardRefs.current[idx] = element
                }}
                order={order}
                onEditName={() => {
                  //
                }}
                onToggleShowShipping={() => {
                  //
                }}
                variant={'startPage'}
              />
            )
          )}
        </div>
      )}
      {!desktop && !tabletWide && (
        <ProductsSwiper
          closeNavigationButtons={mobile}
          cardWrapperClassName={styles.cardWrapper}
          withNavigateButtons
          isSliderLoop={false}
          elementsPerRow={mobile ? 1 : 2}
          productElements={orders.map((order, index) => (
            <OrderListItemV2
              key={order.id}
              index={index}
              order={order}
              ref={(element) => {
                cardRefs.current[index] = element
              }}
              onEditName={() => {
                //
              }}
              onToggleShowShipping={() => {
                //
              }}
              tallestItemHeight={tallestItemHeight}
              variant={'startPage'}
            />
          ))}
        />
      )}
    </div>
  )
}
