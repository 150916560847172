import { FC } from 'react'
import clsx from 'clsx'
import { SwiperSlide } from 'swiper/react'
import styles from './StrengthSection.module.scss'

import { Strength } from '@obeta/components/lib/strength/Strength'
import { SwiperSlider } from '@obeta/components/lib/swiper-slider/SwiperSlider'
import { IStrength } from '@obeta/models/lib/models/Strengths/Strengths'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { useRemToPx } from '@obeta/data/lib/hooks/useRemToPixel'

interface IStrengthProps {
  strengths: IStrength[]
  compact?: boolean
}

const StrengthSliderLayout: FC<IStrengthProps> = ({ strengths, compact }) => {
  const spaceBetween = useRemToPx(1)

  return (
    <div className={styles['swiperContainer']}>
      <SwiperSlider
        options={{ slidesPerView: 'auto', spaceBetween, observer: true, resizeObserver: true }}
      >
        {strengths.map((s) => (
          <SwiperSlide key={s.name}>
            <Strength {...s} compact={compact} key={s.name} />
          </SwiperSlide>
        ))}
      </SwiperSlider>
    </div>
  )
}

const StrengthGridLayout: FC<IStrengthProps> = ({ strengths, compact }) => (
  <div className={clsx(styles['strength-wrapper'], { [styles.compact]: compact })}>
    {strengths.map((s) => (
      <Strength {...s} compact={compact} key={s.name} />
    ))}
  </div>
)

export const StrengthSection: FC<IStrengthProps> = (props) => {
  const { strengths, compact = false } = props
  const { mobile } = useBreakpoints()

  if (mobile) {
    return <StrengthSliderLayout strengths={strengths} compact={compact} />
  }

  return <StrengthGridLayout strengths={strengths} compact={compact} />
}
