import { IObetaCategory } from './types'

export const teasersTop = [
  {
    teaserText: 'Startklar',
    link: '',
    size: 'medium',
    imgUrl: '',
  },
  {
    teaserText: 'Startklar',
    link: '',
    size: 'medium',
    imgUrl:
      'https://i.picsum.photos/id/512/550/550.jpg?hmac=DHOk2iWwmTrXjcfG9W_0InQbfMldHMt4ObmW78v1SXU',
  },
]

export const teasers = [
  {
    teaserText: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
    link: '',
    size: 'small',
    imgUrl:
      'https://i.picsum.photos/id/512/550/550.jpg?hmac=DHOk2iWwmTrXjcfG9W_0InQbfMldHMt4ObmW78v1SXU',
  },
  {
    teaserText: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
    link: '',
    size: 'small',
    imgUrl:
      'https://i.picsum.photos/id/512/550/550.jpg?hmac=DHOk2iWwmTrXjcfG9W_0InQbfMldHMt4ObmW78v1SXU',
  },
  {
    teaserText: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
    link: '',
    size: 'small',
    imgUrl:
      'https://i.picsum.photos/id/512/550/550.jpg?hmac=DHOk2iWwmTrXjcfG9W_0InQbfMldHMt4ObmW78v1SXU',
  },
  {
    teaserText: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
    link: '',
    size: 'large',
    imgUrl: '',
  },
  {
    teaserText: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
    link: '',
    size: 'large',
    imgUrl:
      'https://i.picsum.photos/id/512/550/550.jpg?hmac=DHOk2iWwmTrXjcfG9W_0InQbfMldHMt4ObmW78v1SXU',
  },
]

export const teasersBottom = [
  {
    teaserText: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
    link: '',
    size: 'small',
    imgUrl:
      'https://i.picsum.photos/id/512/550/550.jpg?hmac=DHOk2iWwmTrXjcfG9W_0InQbfMldHMt4ObmW78v1SXU',
  },
  {
    teaserText: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
    link: '',
    size: 'small',
    imgUrl:
      'https://i.picsum.photos/id/512/550/550.jpg?hmac=DHOk2iWwmTrXjcfG9W_0InQbfMldHMt4ObmW78v1SXU',
  },
  {
    teaserText: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
    link: '',
    size: 'small',
    imgUrl:
      'https://i.picsum.photos/id/512/550/550.jpg?hmac=DHOk2iWwmTrXjcfG9W_0InQbfMldHMt4ObmW78v1SXU',
  },
  {
    teaserText: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
    link: '',
    size: 'large',
    imgUrl:
      'https://i.picsum.photos/id/512/550/550.jpg?hmac=DHOk2iWwmTrXjcfG9W_0InQbfMldHMt4ObmW78v1SXU',
  },
  {
    teaserText: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
    link: '',
    size: 'large',
    imgUrl: '',
  },
]
export const newsCards = [
  {
    img: 'https://i.picsum.photos/id/100/320/180.jpg?hmac=RAME2mZho0pZ23FnxFVDGXyeS_i_SaK_jxuEtGLLwyA',
    title: 'BranchNews',
    date: 'Mart 2021',
    theme: 'Busch-Jaeger: Busch-AudioWorld®, Busch-Radio DAB+, Busch-Radio BTconnect DAB+',
    subTheme: 'Die neue Generation des Digitalradios ist da.',
    moreBtn: false,
  },
  {
    img: 'https://i.picsum.photos/id/100/320/180.jpg?hmac=RAME2mZho0pZ23FnxFVDGXyeS_i_SaK_jxuEtGLLwyA',
    title: 'BranchNews',
    date: 'Mart 2021',
    theme: 'Busch-Jaeger: Busch-AudioWorld®, Busch-Radio DAB+, Busch-Radio BTconnect DAB+',
    subTheme: 'Die neue Generation des Digitalradios ist da.',
    moreBtn: true,
  },
  {
    img: 'https://i.picsum.photos/id/100/320/180.jpg?hmac=RAME2mZho0pZ23FnxFVDGXyeS_i_SaK_jxuEtGLLwyA',
    title: 'BranchNews',
    date: 'Mart 2021',
    theme: 'Busch-Jaeger: Busch-AudioWorld®, Busch-Radio DAB+, Busch-Radio BTconnect DAB+',
    subTheme: 'Die neue Generation des Digitalradios ist da.',
    moreBtn: false,
  },
]
export const obetaCategory: IObetaCategory[] = [
  { id: '500', name: 'Leuchten' },
  { id: '385', name: 'Hausgeräte' },
  { id: '130', name: 'Abzweigkästen, Abzweig- und Schalterdosen, Klemmen, Verschraubungen' },
  { id: '940', name: 'Werkzeuge, Mess- und Prüfgeräte' },
  { id: '380', name: 'Verteiler, Zählerschränke, Leergehäuse, Baustromverteiler, SLS-Schalter' },
  { id: '120', name: 'Überspannungsschutz, Erdungsmaterial und Blitzschutz' },
  { id: '970', name: 'Arbeitsschutz, Büro-, Verbrauchs- und Reinigungsartikel' },
  { id: '188', name: 'Rauchmelder, Bewegungsmelder, Präsenzmelder und Dämmerungsschalter' },
  { id: '105', name: 'Kabel, Leitungen, Kabelgarnituren, Kabelschuhe, Isolierbänder' },
  {
    id: '350',
    name: 'Befehls- und Meldegeräte, Industrie Schaltgeräte, Schütze, Industrieverteiler',
  },
  { id: '1362062116679', name: 'Schaltermaterial' },
  { id: '145', name: 'Rohr, Kanal, Kabelrinne und Brandschutz' },
  { id: '160', name: 'Befestigungsmaterial und Gipskartonplatten' },
  { id: '175', name: 'Gebäudeautomation / Smart Home' },
  { id: '180', name: 'Aufputzschaltermaterial' },
  { id: '200', name: 'CEE, Netz-/Geräteanschluss, Verlängerungen, Zeitschaltuhren, E-Mobilität' },
  { id: '320', name: 'Sicherungen, Reiheneinbaugeräte, Stromzähler, Reihenklemmen' },
  { id: '390', name: 'Haustechnik' },
  {
    id: '397',
    name: 'Sprechanlagen, Schwachstrommaterial, Antriebe, Steuerungen, Video und Sicherheitstechnik',
  },
  { id: '400', name: 'Netzwerktechnik' },
  { id: '405', name: 'Telekommunikations- und Medientechnik' },
  { id: '410', name: 'Antennentechnik' },
  { id: '420', name: 'Leuchtmittel, Leuchtenbetriebsgeräte, Fassungen und Leuchtenzubehör' },
]
