import { parseUnformattedNum } from '../parse-num'
import { normalizeNumber } from './normalizeNumber'

export const normalizeAmount = (
  amount: string | number = '-',
  unit: string | undefined = undefined
) => {
  let a
  if (typeof amount === 'number') {
    a = amount
  } else {
    a = parseUnformattedNum(amount)
  }

  if (!unit) {
    return a
  }

  return `${a} ${unit}`
}

export const normalizeInt = (amount: number | string, locale?: string) => {
  return normalizeNumber(amount, 0, locale)
}
