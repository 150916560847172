import React from 'react'
import { StartPageContainer } from '@obeta/layouts/lib/startPage/StartPageContainer'
import { Iframe } from '@obeta/components/lib/legacy-shop/Iframe'
import { useParams } from '@obeta/data/lib/hooks/useHistoryApi'

const StartPageLayout: React.FC = () => <StartPageContainer />

const LogoutPage: React.FC = () => {
  return <Iframe legacyShopPageUrl="logout" />
}

const StartPage: React.FC = () => {
  const params = useParams()
  if (params.logout) return <LogoutPage />
  return <StartPageLayout />
}
export default StartPage

export async function getStaticProps() {
  return {
    props: {},
  }
}
