import React from 'react'
import styles from './Strength.module.scss'
import { Typography } from '@mui/material'

import { ReactComponent as AddShoppingCartIcon } from 'assets/icon/add_shopping_cart_red.svg'
import { ReactComponent as IntegrationInstructions } from 'assets/icon/integration_instructions.svg'
import { ReactComponent as MobileFriendly } from 'assets/icon/mobile_friendly.svg'
import { ReactComponent as Assignment } from 'assets/icon/assignment.svg'
import { ReactComponent as Euro } from 'assets/icon/euro.svg'
import { ReactComponent as SupportAgent } from 'assets/icon/support_agent.svg'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { IStrength } from '@obeta/models/lib/models/Strengths/Strengths'

const iconPath = {
  addShoppingCartIcon: AddShoppingCartIcon,
  integrationInstructions: IntegrationInstructions,
  mobileFriendly: MobileFriendly,
  assignment: Assignment,
  currency: Euro,
  supportAgent: SupportAgent,
}

type IStrengthProps = IStrength

export const Strength: React.FC<IStrengthProps> = (props) => {
  const { icon, name, description, compact = false } = props
  const { mobile } = useBreakpoints()

  const nameTextVariant = mobile ? 'bodyBold' : 'boldText'
  const descriptionTextVariant = mobile ? 'smallText' : 'body'
  const StrengthIcon = iconPath[icon]

  return (
    <div className={styles.strength}>
      <div className={styles.header}>
        <StrengthIcon className={styles['strength-icon']} />
        <Typography variant={nameTextVariant} className={styles['name-wrapper']}>
          {name}
        </Typography>
      </div>
      {!compact && <Typography variant={descriptionTextVariant}>{description}</Typography>}
    </div>
  )
}
