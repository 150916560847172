import React, { useEffect } from 'react'
import { OBETA_IFRAME_URL, ELDIS_IFRAME_URL, HAEUSLER_IFRAME_URL } from '@obeta/utils/lib/config'
import { useUserDataV2 } from '@obeta/data/lib/hooks/useUserDataV2'
import { ShopDomains } from '@obeta/utils/lib/domains'
import styles from './Iframe.module.scss'
import { useHistory } from '@obeta/data/lib/hooks/useHistoryApi'
import { useCompanyDetection } from '@obeta/data/lib/hooks/useCompanyDetection'

interface Props {
  legacyShopPageUrl: string
}

const Routes: Record<ShopDomains, string> = {
  [ShopDomains.Obeta]: OBETA_IFRAME_URL,
  [ShopDomains.Eldis]: ELDIS_IFRAME_URL,
  [ShopDomains.Haeusler]: HAEUSLER_IFRAME_URL,
}

export const Iframe: React.FC<Props> = (props) => {
  const { legacyShopPageUrl } = props
  const { tokens } = useUserDataV2()
  const history = useHistory()
  const userToken = localStorage.getItem('userToken') ?? ''
  const isEmbedded = window.location !== window.parent.location

  const company = useCompanyDetection()
  const baseRoute = Routes[company]

  const iFrameUrl = `${baseRoute}${legacyShopPageUrl}?embedMode=1&token=${
    isEmbedded ? userToken : tokens?.accessToken
  }`

  useEffect(() => {
    if (legacyShopPageUrl === 'logout') {
      history.push('/')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [legacyShopPageUrl])

  return (
    <iframe
      id="shop-next-iframe"
      src={iFrameUrl}
      frameBorder="0"
      title="checkout"
      className={styles.dimensions}
      width="100%"
      scrolling="no"
    />
  )
}
